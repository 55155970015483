import * as React from 'react';

import { navigate } from 'gatsby';
import { Helmet } from 'react-helmet';

import Affiliate from '../components/Attribution/Affiliate';

// URL for HFK - Her First 100k
const HfkPage = () => {
  React.useEffect(() => {
    localStorage.setItem('affiliate', 'hfk');

    navigate('/');
  }, []);

  return (
    <>
      <Affiliate source="hfk" affiliate="hfk" />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
    </>
  );
};

export default HfkPage;
