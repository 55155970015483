import React from 'react';

import { navigate } from 'gatsby';
import { useCookies } from 'react-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Affiliate = ({ source, affiliate, noRedirect, notificationText }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['seq_source']);

  React.useEffect(() => {
    if (affiliate) {
      localStorage.setItem('affiliate', affiliate);
      localStorage.setItem('fromAffiliateToast', 'true');
    }
    if (source) {
      setCookie('seq_source', source, {
        path: '/',
        domain:
          process.env.NODE_ENV === 'production'
            ? '.sequincard.com'
            : 'localhost'
      });
    }

    const notificationMessage = notificationText;
    {
      notificationMessage &&
        toast(notificationMessage, {
          autoClose: false,
          className: 'toast-notification'
        });
    }

    if (!noRedirect) {
      navigate('/');
    }
  }, []);

  return (
    <>
      <ToastContainer />
    </>
  );
};

export default Affiliate;
